<template>
  <footer class="Footer bg-lightgray pt-8 md:pt-16 pb-4 mt-16 md:mt-20">
    <div class="constrain">
      <div class="row-12">
        <div
          class="col-12 lg:col-1 lg:offset-1 lg:order-last flex lg:flex-col
        justify-center lg:justify-start lg:items-start mb-8 lg:mb-0"
        >
          <div
            v-for="page in footerLinks"
            :key="page.id"
            class="last:ml-4 Footer-link"
          >
            <a

              :href="page.uri"
              class="block"
            >
              {{ page.title }}
            </a>

          </div>
        </div>
        <div class="lg:col-2 lg:offset-1 flex justify-center lg:items-start lg:justify-start">
          <Go :to="footerContent.linkedin" class="flex items-center mb-4">
            <LinkedIn />
            <p class="ml-4">
              LinkedIn
            </p>
          </Go>
        </div>
        <div class="lg:col-2 flex flex-col items-center lg:items-start mb-4">
          <div class="flex items-center mb-4">
            <Phone class="mr-4" />
            <a :href="'tel:' + footerContent.telefon">{{ footerContent.telefon }}</a>
          </div>
          <div class="flex items-center mb-4">
            <Mail class="mr-4" />
            <a :href="'mailto:' + footerContent.email">{{ footerContent.email }}</a>
          </div>
        </div>

        <div class="lg:col-5 lg:order-first">
          <div class="Footer-title text-center lg:text-left" v-html="footerContent.firmentext" />
          <div class="Footer-credits text-center lg:text-left" v-html="footerContent.credit" />
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import LinkedIn from '../../assets/images/linkedin.svg';
import Phone from '../../assets/images/phone.svg';
import Mail from '../../assets/images/email.svg';

export default {
  components: {
    LinkedIn,
    Phone,
    Mail,
  },
  computed: {
    payload() {
      return this.$store.state.router.layout.footer || {};
    },
    footerContent() {
      return this.$store.state.router.layout.footerContent || {};
    },
    footerLinks() {
      return this.$store.state.router.layout.footer_links;
    },
  },
  mounted() {
    // console.log(this.footerContent);
  },
};
</script>

<style scoped lang="scss">
.Footer {
  color: var(--color-navy);
  font-size: px(22);
  :deep(p) {
    line-height: 1.5;
  }
  &-title {
    :deep(p) {
      line-height: 1;
      font-size: px(24);
      margin-bottom: 1rem;
      @screen md {
        font-size: px(28);
      }
      screen lg {
        margin-bottom: 4rem;
        font-size: px(32);
      }
    }
  }
  &-link {
    &:not(:last-child) {
      margin-right: 2rem;
      @screen lg {
        margin-right: 0;
      }
    }
  }
  &-credits {
    font-size: px(16);
    @screen md {
      font-size: px(20);
    }
  }
  a {
    line-height: 1.5;
    // font-size: px(20);
    &:hover {
      text-decoration: underline;
    }
    @screen lg {
      font-size: px(20);
    }
    @screen xl {
      font-size: px(22);
    }
  }
  p {
    @screen lg {
      font-size: px(20);
    }
    @screen xl {
      font-size: px(22);
    }
  }
  svg {
    height: px(25);
    width: px(25);
    color: var(--clor-navy);
    @screen lg {
      // height: px(30);
      // width: px(30);
    }
  }
}
</style>
