import { createStore, createLogger } from 'vuex';
import router from './modules/router';

const debug = process.env.NODE_ENV !== 'production';

export default createStore({
  modules: {
    router,
  },
  state: {
    user: false,
  },
  mutations: {
    setUser: (state, val) => {
      state.user = val;
    },
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});
