<template>
  <div class="constrain member-nav-wrapper md:mt-16">
    <div class="row-12">
      <div class="md:col-2">
        <nav v-if="$store.state.user" class="member-nav flex flex-col p-8">
          <Go
            v-for="page in navigation"
            :key="page.id"
            :to="page.uri"
            class="uppercase py-2 mb-2 md:mb-4"
            :class="{ active: page.slug === $store.state.router.page.meta?.slug }"
          >
            {{ page.pageTitle }}
          </Go>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    payload: Object,
  },
  computed: {
    navigation() {
      return this.$store.state.router.layout.memberPages;
    },
  },
  watch: {
    navigation() {
      console.log(this.navigation);
    },
  },
  mounted() {
    // console.log(this.navigation);
  },
};
</script>

<style lang="scss" scoped>
.member-nav-wrapper {
  pointer-events: none;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.member-nav {
  pointer-events: all;
  background: var(--color-light-gray);
  color: var(--color-navy);
  a {
    align-self: flex-start;
    font-weight: 600;
    font-size: px(18);
    background-image: linear-gradient(var(--color-navy), var(--color-navy));
    background-size: 0 px(2);
    background-repeat: no-repeat;
    background-position: bottom;
    will-change: background-size;
    transition: background-size .25s ease-out;
    &:last-child {
      margin-bottom: 0;
    }
    &.active {
    background-size: 100% px(2);
    color: var(--color-navy);
    }
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-size: 100% px(2);
        color: var(--color-navy);
      }
    }
  }
}
</style>
