<template>

  <div id="nav">
    <div class="constrain flex justify-end items-center mb-4">
      <div>
        <Go
          v-if="$store.state.user && !isMobileDevice"
          :to="'/' + locale +'/mitgliederbereich'"
          class="inline-block uppercase link text-navy py-2"
          :class="{ active: $store.state.user && path?.match(/mitgliederbereich/) }"
        >
          Mitgliederbereich
        </Go>
      </div>
      <Language class="md:ml-4 hidden md:flex" />
    </div>
    <div class="relative constrain flex items-center justify-between">
      <div class="logo">
        <Go :to="'/' + $store.state.router.locale" aria-label="Home">
          <img class="logo-image" :src="logo" alt="siba logo">
        </Go>
      </div>
      <nav class="inner flex items-center">
        <Go
          v-for="link of navigation?.links"
          :key="link.uri"
          :to="link.uri"
          class="mx-3 lg:mx-4 py-2 link text-navy uppercase"
          :class="{ active: link.slug === $store.state.router.page.meta?.slug }"
          v-text="link.title"
        />
        <Button
          v-if="!$store.state.user"
          class="login-btn util-btn"
          text="login"
          :link="'/' + locale +'/mitgliederbereich'"
        />
        <Button
          v-else
          class="login-btn util-btn"
          text="logout"
          @click.prevent="logout()"
        />
      </nav>
      <Hamburger
        class="md:hidden"
        :class="{ active: mobile }"
        @click="toggle()"
      />
    </div>
  </div>

  <div class="mobile-wrapper">
    <div class="backdrop" :class="{ active: mobile }" />
    <transition-group name="appear">
      <div v-if="mobile" class="mobile-nav">
        <Go
          v-for="link of navigation?.links"
          :key="link.uri"
          :to="link.uri"
          class="link"
          :class="{ active: link.slug === $store.state.router.page.meta?.slug }"
          @click="toggle(true)"
          v-text="link.title"
        />
        <Language class="mt-6 mb-6 md:hidden" />
        <div>
          <Go
            v-if="isLoggedIn && isMobileDevice"
            :to="'/' + locale +'/mitgliederbereich'"
            class="inline-block link text-bluegray py-2"
            :class="{ active: isLoggedIn && path?.match(/mitgliederbereich/) }"
          >
            Mitgliederbereich
          </Go>

        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>

import Scrolllock from '@/hooks/scrolllock';
import Language from '../utils/Language.vue';
import Hamburger from '../utils/Hamburger.vue';
import Button from '../utils/Button.vue';
import { checkLoggedIn } from '@/utils/loggedin';

export default {
  components: {
    Language,
    Hamburger,
    Button,
  },
  setup() {
    const { lock, unlock } = Scrolllock();

    return {
      lock,
      unlock,
    };
  },
  data: () => ({
    mobile: false,
    isLoggedIn: true,
  }),
  computed: {
    locale() {
      return this.$store.state.router.locale;
    },
    logo() {
      return this.$store.state.router.layout.logo;
    },
    navigation() {
      return this.$store.state.router.layout.navigation;
    },
    isMobileDevice() {
      return window.innerWidth <= 1024;
    },
    path() {
      return this.$store.state.router.page.meta?.translatedPaths.de;
    },
  },
  mounted() {
    // console.log(this.isMobileDevice);
  },
  methods: {
    async logout() {
      const response = await fetch(
        `${this.$store.state.router.base}/auth/logout`,
        {
          method: 'GET',
          credentials: 'include',
        },
      );
      checkLoggedIn(this.$store);
      document.location.href = '/';
    },
    toggle(close) {
      if (close === undefined) close = this.mobile;
      if (close) {
        this.mobile = false;
        setTimeout(() => this.unlock(), 400);
      } else {
        this.mobile = true;
        setTimeout(() => this.lock(), 400);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  position: relative;
  margin-right: 1rem;
  transition: max-width .25 s ease-out;

  &-image {
    max-width: px(100);
    @screen sm {
      max-width: px(125);
    }
    @screen md {
      max-width: px(155);
    }
    @screen lg {
      max-width: px(175);
    }
    @screen xl {
      max-width: px(200);
    }
  }

 a {
    display: flex;
    flex-direction: column;
  }

}

#nav {
  position: fixed;
  @screen md {
    position: relative;
  }
  top: 0;
  z-index: 100;
  width: 100vw;
  padding: px(20) 0;
  // pointer-events: none;
  background-color: var(--color-white);

  @media (max-width: theme("screens.md")) {
    padding-top: px(20);

    .inner {
      position: absolute;
      top: 100%;
      left: 0;
      max-height: 0;
      width: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      background: white;
    }
  }
}

.inner,
.mobile-nav,
:deep(.Language),
.hamburger {
  pointer-events: all;
}

.link {
  font-weight: 600;
  font-size: px(22);
  background-image: linear-gradient(var(--color-navy), var(--color-navy));
  background-size: 0 px(2);
  background-repeat: no-repeat;
  background-position: bottom;
  will-change: background-size;
  transition: background-size .25s ease-out;
  @screen sm {
    font-size: px(24);
  }
  @screen md {
    font-size: px(16);
  }
  @screen lg {
    font-size: px(16);
  }
  @screen xl {
    font-size: px(18);
  }
  &.active {
    background-size: 100% px(2);
    color: var(--color-navy);
  }
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-size: 100% px(2);
      color: var(--color-navy);
    }
  }
}

.login-btn {
  margin-left: 1rem;
  background-color: var(--color-white);
  color: var(--color-blue);
  padding: px(8) px(20);
  &:hover {
    background-color: var(--color-blue);
    color: var(--color-white);
  }
  @screen xl {
    font-size: px(18);
  }
}

/* MOBILE NAV */
.backdrop {
  content: "";
  position: fixed;
  z-index: 98;
  top: -30vh; // -30 for ios overscroll
  left: 0;
  width: 100%;
  height: 160vh; // +2*30 for ios overscroll
  background: rgba(255, 255, 255, 0);
  transition: 0.5s ease;
  pointer-events: none;
  &.active {
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(7px);
    pointer-events: all;
  }
}

.mobile-nav {
  position: fixed;
  overflow-y: scroll;

  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  min-height: 100vh;
  width: 100%;
  font-size: 2rem;

  a {
    padding: 0.5rem 0;
    text-decoration: none !important;
  }
}
</style>
