<template>
  <div class="Language">
    <transition-group tag="ul" name="list" class="inner flex">
      <li
        v-for="(locale, index) in $store.state.router.locales"
        :key="index"
        class="lang"
        :class="{active: locale === activeLanguage}"
      >
        <a :href="getAlt(locale)" @click.prevent="swap(locale)">{{ locale }}</a>
      </li>
    </transition-group>
  </div>
</template>

<script>
export default {
  computed: {
    activeLanguage() {
      return this.$store.state.router.locale;
    },
  },
  mounted() {
    // console.log(this.$store.state.router.locale);
  },
  methods: {
    swap(locale) {
      this.$store.dispatch('router/push', { uri: this.getAlt(locale) });
    },
    getAlt(locale) {
      return this.$store.state.router.page.meta?.translatedPaths[locale];
    },
  },
};
</script>

<style scoped lang="scss">
.Language {
  position: relative;
  // display: flex;
  text-transform: uppercase;
  // width: 50px;
  // height: 35px;
  // margin-top: 7px;
  // font-size: 0.8em;
  // font-weight: 500;
  // white-space: nowrap;

  .inner {
    // position: absolute;
    // top: 0;
    // left: 0;
    // z-index: 1000;
    // width: 45px;
    // border: 1px solid transparent;
    // border-radius: 99px;
    // padding: 0 8px;
    // overflow: hidden;
    // transition: 0.25s ease;

    li {
      display: inline-block;
      position: relative;
      font-size: px(18);
      font-weight: 400;
      &:nth-child(1):after {
        position: absolute;
        content: '';
        top: 50%;
        // right: 0;
        height: 60%;
        width: 2px;
        transform: translateY(-50%);
        background-color: var(--color-navy);
      }
      &.active {
        color: var(--color-navy);
        font-weight: 600;
      }
      a:hover {
        color: var(--color-navy);
      }
    }
    a {
      padding: 6px 12px;
      display: inline-block;
      font-size: px(16);
      @screen xl {
        font-size: px(18);
        font-weight: 600;
      }
    }
  }

  // &:hover {
  //   .inner {
  //     border-color: black;
  //     width: 100px;
  //   }
  //   a {
  //     opacity: 1;
  //   }
  // }

  @media (max-width: theme("screens.md")) {
    font-size: 0.8rem;
    // margin-top: 0px;

    .inner {
      padding: 0 4px;
    }

    &:hover {
      .inner {
        background: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(6px);
        width: 92px;
      }
    }
  }
}

.list-move {
  transition: transform 0.3s cubic-bezier(0.59, 0.12, 0.34, 0.95);
}
</style>
