<template>
  <Go
    :to="link"
    class="util-btn uppercase"
  >
    {{ text }}
  </Go>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.util-btn {
  border: 2px solid var(--color-blue);
  background-color: var(--color-blue);
  color: var(--color-white);
  border-radius: px(100);
  font-size: px(16);
  letter-spacing: 0.05rem;
  font-weight: 600;
  padding: px(15) px(30);
  transition: background-color .25s ease-out, color .25s ease-out;
  @include hover {
    cursor: pointer;
    background-color: var(--color-white);
    color: var(--color-navy);
  }
}
</style>
