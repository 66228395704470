/* eslint-disable */
export async function checkLoggedIn(store) {
  const response = await fetch(
    `${store.state.router.base}/auth/isloggedin`,
    {
      method: 'GET',
      credentials: 'include',
    },
  );
  const { user } = await response.json();
  store.commit('setUser', user);
}
