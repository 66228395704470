<template>

  <transition-group name="fade">
    <component
      :is="component.component"
      v-for="component of parts"
      :id="component.slug || component.data.slug"
      :key="component.id || component.data.id"
      :payload="component.data || component"
      class="fade-item"
    />
    <!-- <Footer key="footer" class="fade-item" /> -->
  </transition-group>

</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
  components: {
    HomepageIntro: defineAsyncComponent(() => import('@/components/parts/HomepageIntro.vue')),
    Header: defineAsyncComponent(() => import('@/components/parts/Header.vue')),
    HeaderSubseite: defineAsyncComponent(() => import('@/components/parts/HeaderSubseite.vue')),
    Breitbild: defineAsyncComponent(() => import('@/components/parts/Breitbild.vue')),
    NewsKacheln: defineAsyncComponent(() => import('@/components/parts/NewsKacheln.vue')),
    NewsTeaser: defineAsyncComponent(() => import('@/components/parts/NewsTeaser.vue')),
    TextundBild: defineAsyncComponent(() => import('@/components/parts/TextundBild.vue')),
    Textblock: defineAsyncComponent(() => import('@/components/parts/Textblock.vue')),
    Bildblock: defineAsyncComponent(() => import('@/components/parts/Bildblock.vue')),
    Team: defineAsyncComponent(() => import('@/components/parts/Team.vue')),
    Mitglieder: defineAsyncComponent(() => import('@/components/parts/Mitglieder.vue')),
    GrndeMitgliedschaft: defineAsyncComponent(() => import('@/components/parts/GrndeMitgliedschaft.vue')),
    Voraussetzungen: defineAsyncComponent(() => import('@/components/parts/Voraussetzungen.vue')),
    Kursanbieter: defineAsyncComponent(() => import('@/components/parts/Kursanbieter.vue')),
    Beitragsregelung: defineAsyncComponent(() => import('@/components/parts/Beitragsregelung.vue')),
    Downloads: defineAsyncComponent(() => import('@/components/parts/Downloads.vue')),
    Kontaktdaten: defineAsyncComponent(() => import('@/components/parts/Kontaktdaten.vue')),
    Kontaktformular: defineAsyncComponent(() => import('@/components/parts/Kontaktformular.vue')),
    Kontaktblock: defineAsyncComponent(() => import('@/components/parts/Kontaktblock.vue')),
    Video: defineAsyncComponent(() => import('@/components/parts/Video.vue')),
    Seitentitelintern: defineAsyncComponent(() => import('@/components/frontenduser/Seitentitelintern.vue')),
    NewsIntern: defineAsyncComponent(() => import('@/components/frontenduser/NewsIntern.vue')),
    DownloadsIntern: defineAsyncComponent(() => import('@/components/frontenduser/DownloadsIntern.vue')),
    TextblockIntern: defineAsyncComponent(() => import('@/components/frontenduser/TextblockIntern.vue')),
    GalerienUebersicht: defineAsyncComponent(() => import('@/components/frontenduser/GalerienUebersicht.vue')),
    Bildergalerieintern: defineAsyncComponent(() => import('@/components/frontenduser/Bildergalerieintern.vue')),
    NewsletterIntern: defineAsyncComponent(() => import('@/components/frontenduser/NewsletterIntern.vue')),
    DownloadsAkkordeon: defineAsyncComponent(() => import('@/components/frontenduser/DownloadsAkkordeon.vue')),
    MeinProfil: defineAsyncComponent(() => import('@/components/frontenduser/MeinProfil.vue')),
    NotFound: defineAsyncComponent(() => import('@/components/structure/NotFound.vue')),

    Loginblock: defineAsyncComponent(() => import('@/components/frontenduser/Loginblock.vue')),

    TextWall: defineAsyncComponent(() => import('@/components/parts/TextWall.vue')),

  },
  props: { inject: { type: Object, default: Object } },
  computed: {
    parts() {
      return this.inject.filter((part) => !part.data?.hidden);
    },
  },
};
</script>
