<template>

  <div id="overlay" />

  <Navigation />

  <main :class="$store.state.router.page.meta?.background">
    <MemberNavigation v-if="isLoggedIn && path?.match(/mitgliederbereich/gi)" />
    <Dynamic v-if="content" :inject="content" />
    <Footer />
  </main>

</template>

<script>

import Navigation from '@/components/structure/Navigation.vue';
import MemberNavigation from '@/components/structure/MemberNavigation.vue';
import Footer from '@/components/structure/Footer.vue';
import Dynamic from '@/components/structure/Dynamic.vue';
import dynamicPage from '@/hooks/dynamicPage';

export default {
  components: {

    Navigation,
    MemberNavigation,
    Footer,
    Dynamic,

  },

  setup() {
    dynamicPage();
  },
  data() {
    return {
      isLoggedIn: true,
    };
  },
  computed: {
    content() {
      return this.$store.state.router.page?.content;
    },
    path() {
      return this.$store.state.router.page.meta?.translatedPaths.de;
    },
    // slug() {
    //   return this.$store.state.router.page.meta?.slug;
    // },
    // parentSlug() {
    //   return this.$store.state.router.page.meta?.parentSlug;
    // },
    // hasGradient3() {
    //   return this.slug === 'home' || this.slug === 'news';
    // },
    // hasGradient2() {
    //   return this.slug === 'unsere-mitglieder' || this.slug === 'mitglied-werden'
    //   || this.slug === 'kontakt' || this.slug === 'vorstand' || this.parentSlug === 'aus-weiterbildung';
    // },
  },
};
</script>

<style lang="scss" scoped>
.gradient1 {
  background-image: linear-gradient(var(--color-white), var(--color-light-gray) 10%);
}
.gradient2 {
  background-image: linear-gradient(var(--color-white), var(--color-light-gray), var(--color-white));
}
</style>
